import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';


/**
 * Given an unknown error, return its message.
 */
export const getErrorMessage = (e: unknown) => {
  if (e instanceof Error) {
    return e.message;
  }

  if (typeof e === 'string') {
    return e;
  }

  return undefined;
};


/**
 * Hash a string to a number.
 */
export const hashStringToNumber = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

/**
 * Generate a pseudo-random number between min and max, from a seed.
 */
export const seededRandom = (seed: string | number, min = 0, max = 1) => {
  // If seed is a string, convert it to a number
  if (typeof seed === 'string') {
    seed = hashStringToNumber(seed);
  }

  const x = Math.sin(seed) * 10000;
  return min + (x - Math.floor(x)) * (max - min);
};

/**
 * Tailwind-friendly class name merging via `twMerge` and `clsx`.
 */
export const cn = (...args: ClassValue[]) => {
  return twMerge(clsx(...args));
};


/**
 * Truncate a number to a specified number of decimals, without rounding.
 * This is also more performant than simply calling `.toFixed(decimals)`.
 */
export const truncateToDecimals = (value: string | number, decimals = 4) => {
  // Convert the input to a number if it's a string
  const num = typeof value === 'string' ? parseFloat(value) : value;

  // Calculate the factor to multiply and divide by based on the number of decimals
  const factor = 10 ** decimals;

  // Truncate the number to the specified decimal points without rounding
  return Math.trunc(num * factor) / factor;
};
